/* global Component */
class favouriteOrderViewComponent extends Component {

    static name() {
        return "favouriteOrderViewComponent";
    }

    static componentName() {
        return "favouriteOrderViewComponent";
    }

    data() {
        return {
            listMode: true,
            columns: ['Name', "Actions"],
            css: {
                ascendingIcon: 'blue chevron up icon',
                descendingIcon: 'blue chevron down icon',
                table: {
                    loadingClass: 'loading',
                    ascendingIcon: 'blue chevron up icon',
                    descendingIcon: 'blue chevron down icon',
                }
            }
        };
    }

    getComputed() {
        return {
            favouritesOrders_list: function () {
                if(this.$store.getters.getAllFavouritesOrders){
                    let FavouriteOrderToObjArray = [];
                    for(let fo of this.$store.getters.getAllFavouritesOrders) {
                        let foObj = {};
                        foObj.Name = fo[0];
                        FavouriteOrderToObjArray.push(foObj);
                    }
                    return FavouriteOrderToObjArray;
                }
                else
                    return [];
            },
            options: function (h) {
                return {
                    preserveState: true,
                    filterByColumn: false,
                    filterable: ["Name"],
                    /* orderBy: {
                         column: "SerNr",
                         ascending: false
                     },*/
                    headings: {
                        'Name': this.tr('Saved as'),
                        'Actions': this.tr('Actions')
                    },
                    perPage: 10,
                    pagination: {
                        chunk: 15,
                    },
                    templates: {
                        Actions: 'FavouriteOrderActionsComponent',
                    },
                    texts: {
                        count: `Showing {from} to {to} of {count} ${this.tr('Orders')}|{count} ${this.tr('Orders')}|One ${this.tr('Order')}`,
                        filter: '',
                        limit: '',
                        filterPlaceholder: '',
                        noResults: this.tr('No favorite orders found'),
                        page: this.tr('Page') + ":", // for dropdown pagination
                        filterBy: 'Filtrado por {column}', // Placeholder for search fields when filtering by column
                        loading: this.tr('Loading') + '...', // First request to server
                        defaultOption: 'Select {column}', // default option for list filters,
                    },
                    dateFormat: "DD-MM-YYYY",
                    uniqueKey: "SerNr"
                };
            }
        };
    }

    getMethods() {
        return {
            goBack: this.goBack
        };
    }

    goBack() {
        this.emitEvent('reset-order-view');
    }


    getTemplate() {
        return `<div class="orders-list">
                  <div class="container-fluid px-0 px-md-3">
                      <div class="section-main m-0">
                          <favouriteOrderDetailModalComponent></favouriteOrderDetailModalComponent>
                          <template v-if="$store.getters.getAppState=='ready'">
                              <div class="row">
                                  <div class="col-12">
                                    <div id="#favouriteorderList">
                                        <h5 class="row title">
                                            <span class="col-12 col-md-4 text-center text-md-left mb-2">{{tr('Favorites Orders List')}}</span>
                                            <div class="col-12 col-md-8 text-center text-md-right">
                                                <button class="btn secondary-btn" @click='goBack'><i class="icon fas fa-history"></i>{{tr('Historical orders')}}</button>
                                            </div>
                                        </h5>
                                        <v-client-table class="custom-vue-table" :key="this.$route.params.filter" @rowclick="" :columns="columns" :data="favouritesOrders_list" :options="options" :css="css">
                                            <div slot="afterFilter" class="VueTables__search-field">
                                               <div class="input-group-append">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon fas fa-search"></i></span>
                                              </div>
                                            </div>
                                        </v-client-table>
                                    </div>
                                </div>
                              </div>
                          </template>
                      </div>
                  </div>
                </div>`;
    }
}

favouriteOrderViewComponent.registerComponent();